import { Link, useLocation } from 'react-router-dom';
import { useGlobalContext } from '../core/global/context.global';

function MainNavigator() {
    const { toggleMenuActive } = useGlobalContext();

    const handleMenuButtonClick = () => {
        toggleMenuActive();
    };

    const location = useLocation();

    const isNavLinkActive = (path) => {
        return location.pathname === path ? 'current-menu-item' : '';
    };

    return (
        <ul className="main-menu">
            <li onClick={handleMenuButtonClick} className={`menu-item ${isNavLinkActive('/')}`}><Link to="/">Home</Link></li>
            <li onClick={handleMenuButtonClick} className={`menu-item ${isNavLinkActive('/portfolio')}`}><Link to="/portfolio">Portfolio</Link></li>
            <li onClick={handleMenuButtonClick} className={`menu-item ${isNavLinkActive('/background')}`}><Link to="/background">Background</Link></li>
            <li onClick={handleMenuButtonClick} className={`menu-item ${isNavLinkActive('/contact')}`}><Link to="/contact">Contact</Link></li>
            <li onClick={handleMenuButtonClick} className={`menu-item ${isNavLinkActive('/articles')}`}><Link to="/articles">Articles</Link></li>
            <li onClick={handleMenuButtonClick} className={`menu-item ${isNavLinkActive('/certificates')}`}><Link to="/certificates">Certifications</Link></li>
        </ul>
    )
}

export default MainNavigator;