import React from 'react';
import data from "../core/data.json";
import PortfolioProjectComponent from '../components/portfolio/portfolio-project.component';

const PortfolioComponent = () => {
    const project_details = data.portfolio_project_data;

    return (
        <div className="container-fluid">
            <div className="row p-30-0">
                <div className="col-lg-12">
                    <div className="art-section-title">
                        <div className="art-title-frame">
                            <h4>Portfolio</h4>
                        </div>
                    </div>
                </div>
                <div className="art-grid art-grid-2-col art-gallery">
                    {project_details.map((obj, index) => (
                        <PortfolioProjectComponent key={obj.id} id={obj.id} name={obj.name} image={obj.image} description={obj.description} link={obj.link} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PortfolioComponent;
