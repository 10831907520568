import React from 'react';
import { useGlobalContext } from '../core/global/context.global';
import MainNavigator from '../navigation/main.navigation';


const RightVerticalBarLayout = () => {
  const { isMenuActive, toggleMenuActive, isContentActive, infoBarActive, currentPageName } = useGlobalContext();
  const handleMenuButtonClick = () => {
    toggleMenuActive();
  };



  return (
    <div className={`art-menu-bar ${isMenuActive ? 'art-active' : ''}`}>
      <div className="art-menu-bar-frame">
        <div className="art-menu-bar-header">
          <a className={`art-menu-bar-btn ${isMenuActive ? 'art-active' : ''} ${infoBarActive ? 'art-disabled' : ''}`} style={{cursor: 'pointer'}} onClick={handleMenuButtonClick}>
            <span></span>
          </a>
        </div>
        <div className="art-current-page">{currentPageName}</div>
        <div className={`art-scroll-frame ${isContentActive ? 'art-active' : ''}`}>
          <nav id="swupMenu">
            <MainNavigator />
          </nav>
        </div>
      </div>
    </div>
  );
};

export default RightVerticalBarLayout;
