import MainCenterLayout from "../layouts/main-center.layout";
import { useGlobalContext } from '../core/global/context.global';
import ArticlesComponent from "../main_components/articles.component";
import { useEffect } from "react";

function ArticlesPage() {
    const { setCurrentPageName } = useGlobalContext();

    useEffect(() => {
        setCurrentPageName("ARTICLES");
    }, [])

    return (
        <MainCenterLayout customComponent={<ArticlesComponent />} />
    )
}

export default ArticlesPage;