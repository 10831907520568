import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

const TestimonialSlider = ({ testimonials }) => {
    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        if (swiper) {
            const sliderContainer = swiper.el;
            let interval;

            const startAutoSlide = () => {
                interval = setInterval(() => {
                    if (swiper.activeIndex < swiper.slides.length - 1) {
                        swiper.slideNext();
                    } else {
                        swiper.slideTo(0);
                    }
                }, 5000);
            };

            const stopAutoSlide = () => {
                clearInterval(interval);
            };

            sliderContainer.addEventListener('mouseenter', stopAutoSlide);
            sliderContainer.addEventListener('mouseleave', startAutoSlide);

            startAutoSlide();

            return () => {
                stopAutoSlide();
                sliderContainer.removeEventListener('mouseenter', stopAutoSlide);
                sliderContainer.removeEventListener('mouseleave', startAutoSlide);
            };
        }
    }, [swiper]);

    const swiperRef = React.useRef(null);

    const goToNextSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };
    const goToPrevSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return (
        <>
            <div className='col-lg-12'>
                <Swiper
                    ref={swiperRef}
                    slidesPerView={3}
                    spaceBetween={30}
                    speed={1400}
                    loop={true}
                    onSwiper={setSwiper}
                    breakpoints={{
                        1500: {
                            slidesPerView: 2,
                        },
                        1200: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        360: {
                            slidesPerView: 1,
                        },
                    }}
                    className="mySwiper art-recommendation-swipper"
                >
                    <div className='swiper-wrapper'>
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide key={index}>
                                <div className='art-a art-testimonial'>
                                    <div className='testimonial-body'>
                                        <img className='art-testimonial-face' src={testimonial.image} alt='Face' />

                                        <h5>{testimonial.name}</h5>
                                        <div className='art-el-suptitle mb-15'>{testimonial.role}</div>
                                        <div className='mb-15'>{testimonial.content}</div>
                                    </div>
                                    <div className='art-testimonial-footer'>
                                        <div className='art-left-side'>
                                            <ul className='art-star-rate'>
                                                {Array.from({ length: testimonial.stars }).map((_, index) => (
                                                    <li key={index}><i className='fas fa-star'></i></li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className='art-right-side'></div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </div>
                </Swiper>
            </div>
            <div className='col-lg-12'>
                <div className='art-slider-navigation'>
                    <div className='art-sn-left'>
                        <div className='swiper-pagination'></div>
                    </div>
                    <div className='art-sn-right'>
                        <div className='art-slider-nav-frame'>
                            <div className='art-slider-nav art-testi-swiper-prev' onClick={goToPrevSlide}><i className='fas fa-chevron-left'></i></div>
                            <div className='art-slider-nav art-testi-swiper-next' onClick={goToNextSlide}><i className='fas fa-chevron-right'></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TestimonialSlider;
