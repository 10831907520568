import LeftVerticalBarLayout from "../layouts/left-vertical-bar.layout"
import RightVerticalBarLayout from "../layouts/right-vertical-bar.layout"
import { useGlobalContext } from "../core/global/context.global";
import MainRouter from "../router/main.router";


function MainView() {
    const { toggleInfoMenuBarActive } = useGlobalContext();

    const handleMobileTopBarClick = () => {
        toggleInfoMenuBarActive();
    };

    return (
        <div className="art-app">
            <div className="art-mobile-top-bar" onClick={handleMobileTopBarClick}></div>
            <div className="art-app-wrapper">
                <div className="art-app-container">
                    <LeftVerticalBarLayout />
                    <MainRouter />
                    <RightVerticalBarLayout />
                </div>
            </div>
        </div>
    )
}

export default MainView;