import React, { createContext, useContext, useState } from 'react';

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
    const [infoBarActive, setInfoBarActive] = useState(false);
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [isContentActive, setIsContentActive] = useState(false);
    const [isInfoMenuBarActive, setInfoMenuBarActive] = useState(false);
    const [currentPageName, setCurrentPageName] = useState("HOME");

    const toggleInfoBarActive = () => {
        setInfoBarActive(prevState => !prevState);
    };

    const toggleMenuActive = () => {
        setIsMenuActive(prevState => !prevState);
        // setIsContentActive(true);
    };

    const toggleContentActive = () => {
        setIsContentActive(prevState => !prevState);
    };

    const toggleInfoMenuBarActive = () => {
        setInfoMenuBarActive(prevState => !prevState);
        setInfoBarActive(false);
        setIsMenuActive(false);
        
    };
    return (
        <GlobalContext.Provider
            value={{
                infoBarActive,
                toggleInfoBarActive,
                isMenuActive,
                toggleMenuActive,
                isContentActive,
                toggleContentActive,
                isInfoMenuBarActive,
                toggleInfoMenuBarActive,
                currentPageName,
                setCurrentPageName
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

const useGlobalContext = () => {
    return useContext(GlobalContext);
};

export { GlobalProvider, useGlobalContext };

