import ArticleCard from "../components/article/article-card.component";
import data from "../core/data.json";

function ArticlesComponent() {
    const articles = data.article_data;
    return (
        <div className="container-fluid">
            <div className="row p-30-0">
                <div className="col-lg-12">
                    <div className="art-section-title">
                        <div className="art-title-frame">
                            <h4>Articles</h4>
                        </div>
                    </div>
                </div>
                {articles.map((obj, index) => (
                    <ArticleCard key={index} name={obj.name} description={obj.description} link={obj.link} image={obj.image} />
                ))}
            </div>
        </div>
    )
}

export default ArticlesComponent;