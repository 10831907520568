function EducationTimelineItem({ instituteName, degreeName, dateRange, description }) {
    return (
        <div className="art-timeline-item">
            <div className="art-timeline-mark-light"></div>
            <div className="art-timeline-mark"></div>

            <div className="art-a art-timeline-content">
                <div className="art-card-header">
                    <div className="art-left-side">
                        <h5>{instituteName}</h5>
                        <div className="art-el-suptitle mb-15">{degreeName}</div>
                    </div>
                    <div className="art-right-side">
                        <span className="art-date">{dateRange}</span>
                    </div>
                </div>

                <p>{description}</p>
            </div>
        </div>
    )
}

function ExperienceTimelineItem({companyName, designation, dateRange, description}) {
    return (
        <div className="art-timeline-item">
            <div className="art-timeline-mark-light"></div>
            <div className="art-timeline-mark"></div>
            <div className="art-a art-timeline-content">
                <div className="art-card-header">
                    <div className="art-left-side">
                        <h5>{companyName}</h5>
                        <div className="art-el-suptitle mb-15">{designation}</div>
                    </div>
                    <div className="art-right-side">
                        <span className="art-date">{dateRange}</span>
                    </div>
                </div>
                <p>{description}</p>
            </div>
        </div>
    )
}

export { EducationTimelineItem, ExperienceTimelineItem };