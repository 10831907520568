import React from 'react';

const HomeServiceComponent = ({service}) => {
    return (
        <div className="col-lg-4 col-md-6">
            <div className="art-a art-service-icon-box">
                <div className="art-service-ib-content">
                    <h5 className="mb-15">{service.title}</h5>
                    <div className="mb-15">{service.description}</div>
                </div>
            </div>
        </div>
    );
};

export default HomeServiceComponent;
