import React, { useEffect, useRef } from 'react';
import anime from 'animejs';

const IncrementalCounter = ({ startValue, endValue, delay }) => {
    const counterRef = useRef(null);

    useEffect(() => {
        const counterElement = counterRef.current;

        anime({
            targets: counterElement,
            textContent: [startValue, endValue],
            round: 1,
            easing: 'linear',
            duration: 2000,
            delay: delay,
        });
    }, [startValue, endValue, delay]);

    return <span ref={counterRef}>{startValue}</span>;
};

const CounterComponent = ({ endValue, delay, title }) => {
    return (
        <div className="col-md-3 col-6">
            <div className="art-counter-frame">
                <div className="art-counter-box">
                    <span className="art-counter">
                        <IncrementalCounter startValue={0} endValue={endValue} delay={delay} />
                    </span>
                    <span className="art-counter-plus">+</span>
                </div>
                <h6>{title}</h6>
            </div>
        </div>
    );
};

export default CounterComponent;




