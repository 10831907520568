import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

function PortfolioProjectComponent(props) {
    return (
        <div className="art-grid-item webTemplates">
            <a className="art-a art-portfolio-item-frame art-horizontal">
                <img src={props.image} alt="item" />
            </a>
            <div className="art-item-description">
                <div className='art-item-description-header mb-15'>
                    <h5>{props.name}</h5>
                    <a target="_blank" href={props.link}><FontAwesomeIcon className='art-item-description-header-icon' icon={faGlobe} /></a>
                </div>
                <div className="mb-15">{props.description}</div>
                {/* <a href="/portfolio-single.html" className="art-link art-color-link art-w-chevron">Read more</a> */}
                <Link to={`/portfolio/${props.id}`} className="art-link art-color-link art-w-chevron">Read more</Link>
            </div>
        </div>
    )
}

export default PortfolioProjectComponent;