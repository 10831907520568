import PortfolioComponent from "../main_components/projects.component";
import MainCenterLayout from "../layouts/main-center.layout";
import { useGlobalContext } from '../core/global/context.global';
import { useEffect } from "react";

function PortfolioPage() {
    const { setCurrentPageName } = useGlobalContext();
    // setCurrentPageName("PORTFOLIO");
    useEffect(() => {
        setCurrentPageName("PORTFOLIO");
    }, [])

    return (
        <MainCenterLayout customComponent={<PortfolioComponent />} />
    )
}

export default PortfolioPage;