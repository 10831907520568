import React, { useEffect, useRef } from 'react';
import ProgressBar from 'progressbar.js';

function LineProgressBar({ delay, value }) {
  const progressRef = useRef();

  useEffect(() => {
    const bar = new ProgressBar.Line(progressRef.current, {
      strokeWidth: 1.72,
      easing: 'easeInOut',
      duration: 1400,
      delay,
      trailWidth: 1.72,
      svgStyle: {
        width: '100%',
        height: '100%',
      },
      step: (state, bar) => {
        bar.setText(`${Math.round(bar.value() * 100)} %`);
      },
    });

    bar.animate(value);
  }, [delay, value]);

  return (
    <div className="art-line-progress">
      <div ref={progressRef} />
    </div>
  );
}

function HardSkillItem({ heading, delay, value }) {
  return (
    <div className="art-hard-skills-item">
      <div className="art-skill-heading">
        <h6>{heading}</h6>
      </div>
      <LineProgressBar delay={delay} value={value} />
    </div>
  );
}

export default HardSkillItem;
