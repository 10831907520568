import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { GlobalProvider } from './core/global/context.global';
import Preloader from './components/pre-loader/pre-loader.component';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GlobalProvider>
    <BrowserRouter>
      <Preloader />
      <App />
    </BrowserRouter>
  </GlobalProvider>
);