import { Routes, Route } from "react-router-dom";

import HomePage from "../views/home.view";
import ContactPage from "../views/contact.view";
import BackgroundPage from "../views/background.view";
import PortfolioPage from "../views/projects.view";
import NoMatchPage from "../views/no-match.view";
import SpecificProjectPage from "../views/specific-project.view";
import ArticlesPage from "../views/articles.view";
import CertificatesPage from "../views/certificates.view";

function MainRouter() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="background" element={<BackgroundPage />} />
            <Route path="portfolio" element={<PortfolioPage />} />
            <Route path="portfolio/:id" element={<SpecificProjectPage />} />
            <Route path="articles" element={<ArticlesPage />} />
            <Route path="certificates" element={<CertificatesPage />} />
            <Route path="*" element={<NoMatchPage />} />
        </Routes>
    )
}

export default MainRouter;