import React from 'react';
import Typewriter from 'typewriter-effect';

const HomeTypingEffect = () => {
  return (
    <div className="art-lg-text art-code mb-25">
      &lt;<i>code</i>&gt; I  
        <Typewriter
          options={{
            strings: [
              'am a full-stack software engineer',
              'build web applications.',
              'build automation tools.'
            ],
            autoStart: true,
            loop: true,
          }}
        />
      &lt;/<i>code</i>&gt;
    </div>
  );
};

export default HomeTypingEffect;