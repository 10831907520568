import BackgroundComponent from "../main_components/background.component";
import MainCenterLayout from "../layouts/main-center.layout";
import { useGlobalContext } from '../core/global/context.global';
import { useEffect } from "react";

function BackgroundPage() {
    const { setCurrentPageName } = useGlobalContext();
    // setCurrentPageName("BACKGROUND");

    useEffect(() => {
        setCurrentPageName("BACKGROUND");
    }, [])

    return (
        <MainCenterLayout customComponent={<BackgroundComponent />} />
    )
}

export default BackgroundPage;