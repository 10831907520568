// Import CSS files
import "./assets/css/plugins/bootstrap.min.css";
import "./assets/css/plugins/font-awesome.min.css";
import "./assets/css/plugins/swiper.min.css";
import "./assets/css/plugins/fancybox.min.css";
import "./assets/css/style.css";

import MainView from "./views/main-layout.view";

function App() {
  return (
    <div className="App">
      <MainView />
    </div>
  );
}

export default App;
