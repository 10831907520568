import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';

export default function ContactForm(props) {
  return (
    <Formik
      initialValues={{ name: '', email: '', number: '', message: '' }}
      // validation
      validationSchema={Yup.object({
        name: Yup.string().max(35, 'Must be 35 characters or less').required('Required'),
        email: Yup.string().email('Invalid email address').required('Required'),
        number: Yup.string(),
        message: Yup.string().max(800, 'Must be 800 characters or less').required('Required'),
      })}
      // form submit
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const contact = JSON.parse(JSON.stringify(values, null, 2));
        setTimeout(() => {
          emailjs
            .send(
              process.env.REACT_APP_EMAIL_SERVICE_ID,
              process.env.REACT_APP_EMAIL_TEMPLATE_ID,
              {
                name: contact.name,
                email: contact.email,
                number: contact.number,
                message: contact.message,
              },
              process.env.REACT_APP_EMAIL_PUBLIC_KEY
            )
            .then(
              (result) => {
                console.log(result.text);
                setTimeout(() => resetForm(), 2000);
              },
              (error) => {
                console.log(error.text);
              }
            );
        }, 400);
      }}
    >
      {(formik) => (
        <Form id='form' className='art-contact-form'>
          <div className='art-form-field'>
            <Field
              id='name'
              name='name'
              className={'art-input' + (formik.values.name ? ' art-active' : '')}
              type='text'
              placeholder='Name'
            />
            <label htmlFor='name'>
              <i className='fas fa-user'></i>
            </label>
            <div className='art-validation-warning'>
              <span>
                <ErrorMessage name='name' />
              </span>
            </div>
          </div>

          <div className='art-form-field'>
            <Field
              id='email'
              name='email'
              className={'art-input' + (formik.values.email ? ' art-active' : '')}
              type='email'
              placeholder='Email'
            />
            <label htmlFor='email'>
              <i className='fas fa-at'></i>
            </label>
            <div className='art-validation-warning'>
              <span>
                <ErrorMessage name='email' />
              </span>
            </div>
          </div>

          <div className='art-form-field'>
            <Field
              id='number'
              name='number'
              className={'art-input' + (formik.values.number ? ' art-active' : '')}
              type='text'
              placeholder='Contact Number'
            />
            <label htmlFor='Contact Number'>
              <i className='fas fa-solid fa-phone'></i>
            </label>
            <div className='art-validation-warning'>
              <span>
                <ErrorMessage name='number' />
              </span>
            </div>
          </div>

          <div className='art-form-field'>
            <Field
              id='message'
              name='message'
              className={'art-input' + (formik.values.message ? ' art-active' : '')}
              placeholder='Message'
              as='textarea'
            />
            <label htmlFor='message'>
              <i className='far fa-envelope'></i>{' '}
            </label>
            <div className='art-validation-warning art-message-validation'>
              <span>
                <ErrorMessage name='message' />
              </span>
            </div>
          </div>

          <div className='art-submit-frame'>
            <button className='art-btn art-btn-md art-submit' type='submit' disabled={formik.isSubmitting}>
              {/* <span>Send Message</span> */}
              <span>{formik.isSubmitting ? 'Submitting...' : 'Send Message'}</span>
            </button>
            <div className='art-success'>
              Success <i className='fas fa-check'></i>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
