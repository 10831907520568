import CertificateCard from "../components/certificate/certificate-card.component";
import data from "../core/data.json";

function CertificatesComponent() {
    const certificates = data.certificates_data;

    return (
        <div className="container-fluid">
            <div className="row p-30-0">
                <div className="col-lg-12">
                    <div className="art-section-title">
                        <div className="art-title-frame">
                            <h4>Certifications</h4>
                        </div>
                    </div>
                </div>
                <div className="art-grid art-grid-2-col art-gallery">
                    {certificates.map((obj, index) => (
                        <CertificateCard key={index} image={obj.image} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CertificatesComponent;