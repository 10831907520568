import React from 'react';
import data from "../core/data.json";
import { EducationTimelineItem, ExperienceTimelineItem } from "../components/background/timeline-item.component";

function BackgroundComponent() {
    const education_details = data.background_education_details;
    const experience_details = data.background_experience_details;

    return (
        <div className="container-fluid">
            <div className="row p-30-0">
                <div className="col-lg-6">
                    <div className="art-section-title">
                        <div className="art-title-frame">
                            <h4>Education</h4>
                        </div>
                    </div>
                    <div className="art-timeline art-gallery">
                        {education_details.map((obj, index) => (
                            <EducationTimelineItem key={index} instituteName={obj.instituteName} degreeName={obj.degreeName} dateRange={obj.dateRange} description={obj.description} />
                        ))}
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="art-section-title">
                        <div className="art-title-frame">
                            <h4>Experience</h4>
                        </div>
                    </div>

                    <div className="art-timeline">
                        {experience_details.map((obj, index) => (
                            <ExperienceTimelineItem key={index} companyName={obj.companyName} designation={obj.designation} dateRange={obj.dateRange} description={obj.description} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BackgroundComponent;