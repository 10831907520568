function ArticleCard(props) {
    return (
        <div className="col-lg-6">
            <div className="art-a art-blog-card">
                <a href={props.link} target="blank" className="art-port-cover">
                    <img src={props.image} alt="Article image" />
                </a>
                <div className="art-post-description">
                    <a href={props.link} target="blank">
                        <h5 className="mb-15">{props.name}</h5>
                    </a>
                    <div className="mb-15">{props.description}</div>
                    <a href={props.link} target="blank" className="art-link art-color-link art-w-chevron">Read more</a>
                </div>
            </div>

        </div>
    )
}


export default ArticleCard;