import MainCenterLayout from "../layouts/main-center.layout";
import SpecificProjectDetailComponent from "../main_components/project-specific.component";
import { useGlobalContext } from '../core/global/context.global';
import { useEffect } from "react";

function SpecificProjectPage() {
    const { setCurrentPageName } = useGlobalContext();
    // setCurrentPageName("PORTFOLIO");

    useEffect(() => {
        setCurrentPageName('PORTFOLIO')
    }, [])

    return (
        <MainCenterLayout customComponent={<SpecificProjectDetailComponent />} />
    )
}

export default SpecificProjectPage;