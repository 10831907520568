import React from 'react';
import { Link } from 'react-router-dom';
import HomeTypingEffect from '../components/home/home-typing.component';
import CounterComponent from '../components/home/home-counter.component';
import HomeServiceComponent from '../components/home/home-services.component';
import TestimonialSlider from '../components/home/home-recommendation.component';
import data from "../core/data.json";

function HomeComponent() {
    const services = data.services;
    const testimonials = data.testimonials;
    const home_counter = data.home_counter

    return (
        <>
            <div className="container-fluid">
                <div className="row p-60-0 p-lg-30-0 p-md-15-0">
                    <div className="col-lg-12">
                        <div className="art-a art-banner" style={{ backgroundImage: "url('/img/background/tunnel.gif')" }}>
                            <div className="art-banner-back"></div>
                            <div className="art-banner-dec"></div>
                            <div className="art-banner-overlay">
                                <div className="art-banner-title">
                                    <h1 className="mb-15">Hey! <br />Welcome to my Portfolio</h1>
                                    <HomeTypingEffect />
                                    <div className="art-buttons-frame">
                                        <Link className="art-btn art-btn-md" to="/portfolio">
                                            <span>
                                                Explore now
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <img src="/img/bitmoji/hi-wave.png" className="art-banner-photo" alt="Your Name" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row p-30-0">
                    {home_counter.map((obj, index) => (
                        <CounterComponent key={index} title={obj.title} delay={obj.delay} endValue={obj.endValue} />
                    ))}
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className="col-lg-12">
                        <div className="art-section-title">
                            <div className="art-title-frame">
                                <h4>My Expertise</h4>
                            </div>
                        </div>
                    </div>
                    {services.map((service, index) => (
                        <HomeServiceComponent key={index} service={service} />
                    ))}
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className="col-lg-12">
                        <div className="art-section-title">
                            <div className="art-title-frame">
                                <h4>Recommendations & Reviews</h4>
                            </div>
                        </div>
                    </div>
                    <TestimonialSlider testimonials={testimonials} />
                </div>
            </div>
        </>
    );
}

export default HomeComponent;
