import ContactComponent from "../main_components/contact.component";
import MainCenterLayout from "../layouts/main-center.layout";
import { useGlobalContext } from '../core/global/context.global';
import { useEffect } from "react";

function ContactPage() {
    const { setCurrentPageName } = useGlobalContext();
    // setCurrentPageName("CONTACT");
    useEffect(() => {
        setCurrentPageName("CONTACT");
    }, [])

    return (
        <MainCenterLayout customComponent={<ContactComponent />} />

    )
}

export default ContactPage;