import React, { useEffect, useState } from 'react';
import Scrollbar from 'smooth-scrollbar';
import LanguageCircleProgressBar from './language-circlebar.component';
import HardSkillItem from './skill-progressbar.component';
import ToolsTechnologyItem from './tools-technology-item.component';
import data from "../../core/data.json";


function LeftBarScrollableComponent() {
  const tools = data.tools;
  const skills = data.skills;

  const [scrollbar, setScrollbar] = useState(null);

  useEffect(() => {
    // Initialize Scrollbar
    const scrollbarInstance = Scrollbar.init(document.querySelector('#scrollbar2'), {
      damping: 0.05,
      renderByPixel: true,
      continuousScrolling: true,
    });

    // Save the scrollbar instance in the state
    setScrollbar(scrollbarInstance);

    // Clean up the scrollbar instance on unmount
    return () => {
      if (scrollbarInstance) {
        scrollbarInstance.destroy();
      }
    };
  }, []);

  return (
    <div id="scrollbar2" className="art-scroll-frame">
      {/* <div className="art-table p-15-15">
        <ul>
          <li>
            <h6>Residence:</h6>
            <span>Pakistan</span>
          </li>
          <li>
            <h6>City:</h6>
            <span>Karachi</span>
          </li>
        </ul>
      </div>
      <div className="art-ls-divider"></div> */}
      <div className="art-lang-skills-head">
        <h5>Languages</h5>
        <div className="art-lang-skills p-30-15">
          <LanguageCircleProgressBar value={0.9} delay={1800} language="English" />
          <LanguageCircleProgressBar value={0.2} delay={1800} language="Deutsch" />
          <LanguageCircleProgressBar value={0.95} delay={1800} language="Urdu" />
        </div>
      </div>
      <div className="art-ls-divider"></div>
      <div className="art-hard-skills-head">
        <h5>Skills and Competencies</h5>
        <div className="art-hard-skills p-30-15">
          {skills.map((obj, index) => (
            <HardSkillItem key={index} heading={obj.heading} delay={obj.delay} value={obj.value} />
          ))}
        </div>
      </div>
      <div className="art-ls-divider"></div>
      <div className="art-tools-tech-head">
        <h5>Tools and Technologies</h5>
        <div className="art-tools-tech">
          {tools.map((name, index) => (
            <ToolsTechnologyItem key={index} name={name} />
          ))}
        </div>
      </div>
      <div className="art-ls-divider"></div>
      <div className="art-personal-info">
        <div className="art-personal-info-item">
          <h6>Email</h6>
          <p>bilalulhaque16@gmail.com</p>
        </div>
        <div className="art-personal-info-item">
          <h6>Phone</h6>
          <p>+92 345 2156788</p>
        </div>
      </div>
      <div className="art-ls-divider"></div>
      <div className="art-links-frame p-15-15">
        <a target="blank" href="https://bilalportfoliobucket.s3.amazonaws.com/Bilal+ul+Haque-Software+Engineer-Resume.pdf" className="art-link" download>Download cv <i className="fas fa-download"></i></a>
      </div>
    </div>
  );
}

export default LeftBarScrollableComponent;
