import React from 'react';
import LeftBarScrollableComponent from '../components/layout/left-bar-scrollable.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faDev, faMedium, faGithub } from "@fortawesome/free-brands-svg-icons"
import { useGlobalContext } from '../core/global/context.global';
import { Link } from 'react-router-dom';
import data from "../core/data.json";



const LeftVerticalBarLayout = () => {
  const { infoBarActive, toggleInfoBarActive, isMenuActive } = useGlobalContext();
  const handleInfoBarClick = () => {
    toggleInfoBarActive();
  };

  return (
    // <div className={`art-info-bar ${infoBarActive ? 'art-active' : ''}`}>
    <div className={`art-info-bar ${infoBarActive ? 'art-active' : ''}`}>

      <div className="art-info-bar-frame">
        <div className="art-info-bar-header">
          <a className={`art-info-bar-btn ${isMenuActive ? 'art-disabled' : ''}`} style={{cursor: 'pointer'}} onClick={handleInfoBarClick}>
            <i className="fas fa-ellipsis-v"></i>
          </a>
        </div>
        <div className="art-header">
          <div className="art-avatar">
            <a data-fancybox="avatar" href={data.personal_details.profile_picture} className="art-avatar-curtain">
              <img src={data.personal_details.profile_picture} alt="avatar" />
              <i className="fas fa-expand"></i>
            </a>
            <div className="art-lamp-light">
              <div className="art-available-lamp"></div>
            </div>
          </div>
          <h5 className="art-name mb-10"><Link to="/">{data.personal_details.name}</Link></h5>
          <div className="art-sm-text">{data.personal_details.profile_description}</div>
        </div>
        <LeftBarScrollableComponent />
        <div className="art-ls-social">
          <a href={data.personal_details.linkedin_link} target="blank"><FontAwesomeIcon icon={faLinkedin} /></a>
          <a href={data.personal_details.medium_link} target="blank"><FontAwesomeIcon icon={faMedium} /></a>
          <a href={data.personal_details.github_link} target="blank"><FontAwesomeIcon icon={faGithub} /></a>
          <a href={data.personal_details.dev_link} target="blank"><FontAwesomeIcon icon={faDev} /></a>
        </div>
      </div>
    </div>
  );
};

export default LeftVerticalBarLayout;