import MainCenterLayout from "../layouts/main-center.layout";
import HomeComponent from "../main_components/home.component";
import { useGlobalContext } from '../core/global/context.global';
import { useEffect } from "react";

function HomePage() {
    const { setCurrentPageName } = useGlobalContext();
    // setCurrentPageName("HOME");
    useEffect(() => {
        setCurrentPageName("HOME");
    }, [])

    return (
        <MainCenterLayout customComponent={<HomeComponent />} />
    )
}

export default HomePage;