function CertificateCard(props) {
    return (
        <div className="art-grid-item webTemplates">
            <a data-fancybox="gallery" href={props.image} className="art-a art-portfolio-item-frame art-horizontal">
                <img src={props.image} alt="item" />
                <span className="art-item-hover"><i className="fas fa-expand"></i></span>
            </a>
        </div>
    )
}

export default CertificateCard;