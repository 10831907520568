import React, { useEffect, useRef } from 'react';
import ProgressBar from 'progressbar.js';

function LanguageCircleProgressBar({ value, delay, language }) {
  const circleRef = useRef();

  useEffect(() => {
    const animateProgressBar = () => {
      const bar = new ProgressBar.Circle(circleRef.current, {
        strokeWidth: 7,
        easing: 'easeInOut',
        duration: 1400,
        delay,
        trailWidth: 7,
        step: (state, circle) => {
          const roundedValue = Math.round(circle.value() * 100);
          circle.setText(roundedValue === 0 ? '' : roundedValue);
        }
      });

      bar.animate(value);
    };

    animateProgressBar();
  }, [value, delay]);

  return (
    <div className="art-lang-skills-item">
      <div ref={circleRef} className="art-cirkle-progress"></div>
      <h6>{language}</h6>
    </div>
  );
}

export default LanguageCircleProgressBar;
