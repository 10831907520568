import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../core/global/context.global';
import Scrollbar from 'smooth-scrollbar';

const MainCenterLayout = ({customComponent}) => {
  const { infoBarActive, isMenuActive, toggleInfoMenuBarActive } = useGlobalContext();

  const [scrollbar, setScrollbar] = useState(null);

  const handleCurtainClick = () => {
    toggleInfoMenuBarActive();
  };

  useEffect(() => {
    // Initialize Scrollbar
    const scrollbarInstance = Scrollbar.init(document.querySelector('#scrollbar'), {
      damping: 0.05,
      renderByPixel: true,
      continuousScrolling: true,
    });

    // Save the scrollbar instance in the state
    setScrollbar(scrollbarInstance);

    // Clean up the scrollbar instance on unmount
    return () => {
      if (scrollbarInstance) {
        scrollbarInstance.destroy();
      }
    };
  }, []);


  return (
    <div className={`art-content ${infoBarActive ? 'art-active' : ''} ${isMenuActive ? 'art-active' : ''}`}>
      <div className="art-curtain" onClick={handleCurtainClick}></div>
      {/* <div className="art-top-bg" style={{ backgroundImage: "url(img/background/tunnel.gsif)" }}> */}
      <div className="art-top-bg">
        <div className="art-top-bg-overlay"></div>
      </div>
      <div className="transition-fade" id="swup">
        <div id="scrollbar" className="art-scroll-frame">
          {/* ... Here we can pass the components to be rendered */}
          {customComponent}
          <div className="container-fluid">
            <footer>
              <div>© 2023 Bilal Ul Haque</div>
              <div>+92 345 2156788</div>
              <div>bilalulhaque16@gmail.com</div>
            </footer>

          </div>
        </div>
      </div>
    </div>
  );
};

export default MainCenterLayout;
