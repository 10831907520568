import React from 'react';
import data from "../core/data.json";
import ContactForm from '../components/contact/contact-form.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faDev, faMedium, faGithub } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function ContactComponent() {
    const contact_data = data.contact_page_details;

    return (
        <div className="container-fluid">
            <div className="row p-30-0">
                <div className="col-lg-12">
                    <div className="art-section-title">
                        <div className="art-title-frame">
                            <h4>Contact information</h4>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="art-a art-card">
                        <div className="art-table p-15-15">
                            <ul>
                                <li>
                                    <h6>Country:</h6><span>{contact_data.country}</span>
                                </li>
                                <li>
                                    <h6>City:</h6><span>{contact_data.city}</span>
                                </li>
                                <li>
                                    <h6>Company:</h6><span>{contact_data.company}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="art-a art-card">
                        <div className="art-table p-15-15">
                            <ul>
                                <li>
                                    <h6>Email:</h6><span><a href={`mailto:${contact_data.email}`}>{contact_data.email}</a></span>
                                </li>
                                <li>
                                    <h6>Phone:</h6><span>{contact_data.phone}</span>
                                </li>
                                <li>
                                    <h6>LinkedIn:</h6><span><a href={data.personal_details.linkedin_link} target="blank">{contact_data.linkedin}</a></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 contact-link-card-main">
                    <div className='contact-link-card-sub'>
                        <div className='col-lg-6 contact-link-card'>
                            <a className='contact-link-icon' href={`mailto:${contact_data.email}`}><FontAwesomeIcon icon={faEnvelope} /></a>
                            <a className='contact-link-icon' href={data.personal_details.linkedin_link} target="blank"><FontAwesomeIcon icon={faLinkedin} /></a>
                            <a className='contact-link-icon' href={data.personal_details.medium_link} target="blank"><FontAwesomeIcon icon={faMedium} /></a>
                            <a className='contact-link-icon' href={data.personal_details.github_link} target="blank"><FontAwesomeIcon icon={faGithub} /></a>
                            <a className='contact-link-icon' href={data.personal_details.dev_link} target="blank"><FontAwesomeIcon icon={faDev} /></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="art-section-title">
                        <div className="art-title-frame">
                            <h4>Get in touch</h4>
                        </div>
                    </div>
                    <div className="art-a art-card">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactComponent;
