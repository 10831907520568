import MainCenterLayout from "../layouts/main-center.layout";
import { useGlobalContext } from '../core/global/context.global';
import CertificatesComponent from "../main_components/certificates.component";
import { useEffect } from "react";

function CertificatesPage() {
    const { setCurrentPageName } = useGlobalContext();
    useEffect(() => {
        setCurrentPageName("CERTIFICATIONS");
    }, [])

    return (
        <MainCenterLayout customComponent={<CertificatesComponent />} />

    )
}

export default CertificatesPage;