import MainCenterLayout from "../layouts/main-center.layout";


function NoMatchPage() {
    return (
        <MainCenterLayout customComponent={
            <div className="container-fluid">
                <div className="no-match-container">
                    <div className="not-found">
                        <h1>404 Not Found</h1>
                        <p>The page you're looking for could not be found.</p>
                    </div>
                </div>
            </div>
        } />

    )
}

export default NoMatchPage;