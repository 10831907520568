import React, { useEffect, useRef } from 'react';
import ProgressBar from 'progressbar.js';
import anime from 'animejs';


function Preloader() {
  const preloaderRef = useRef(null);

  useEffect(() => {
    const preloaderContent = preloaderRef.current.querySelector('.art-preloader-content');
    const preloader = preloaderRef.current;

    // Fade in animation
    anime({
      targets: preloaderContent,
      opacity: [0, 1],
      delay: 200,
      duration: 600,
      easing: 'linear',
      complete: () => (preloader.style.display = 'flex'),
    });

    // Fade out animation
    anime({
      targets: preloader,
      opacity: [1, 0],
      delay: 2200,
      duration: 400,
      easing: 'linear',
      complete: () => (preloader.style.display = 'none'),
    });

    // Loading bar animation
    const bar = new ProgressBar.Line('#preloader', {
      strokeWidth: 1.7,
      easing: 'easeInOut',
      duration: 1400,
      delay: 750,
      trailWidth: 1.7,
      svgStyle: {
        width: '100%',
        height: '100%',
      },
      step: (state, bar) => {
        bar.setText(Math.round(bar.value() * 100) + ' %');
      },
    });
    bar.animate(1);
  }, []);

  return (
    <div id="art-preloader" className="art-preloader" ref={preloaderRef}>
      <div className="art-preloader-content">
        <h4>Welcome</h4>
        <div id="preloader" className="art-preloader-load"></div>
      </div>
    </div>
  );
}

export default Preloader;