import React, { useState, useEffect, Profiler } from 'react';
import { useParams } from 'react-router-dom';
import data from "../core/data.json";
import SpecificPortfolioTechStack from '../components/portfolio/specific-porfolio-tech-stack.card';

const SpecificProjectDetailComponent = () => {
    const [portfolioData, setPortfolioData] = useState([]);
    const { id } = useParams();
    const [findPortfolioData, setFindPortfolioData] = useState(null);

    useEffect(() => {
        const fetchData = () => {
            setPortfolioData(data.portfolio_project_data);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (portfolioData.length > 0) {
            const foundData = portfolioData.find(obj => obj.id == id);
            setFindPortfolioData(foundData);
        }
    }, [portfolioData, id]);


    return (
        <>
            <div className="container-fluid">
                <div className="row p-30-0">
                    <div className="col-lg-12">
                        <div className="art-section-title">
                            <div className="art-title-frame">

                                {findPortfolioData ? (<h4>{findPortfolioData.name}</h4>) : (<h4>Project Name</h4>)}
                            </div>
                            <div className="art-right-frame">
                                {findPortfolioData ? (<div className="art-project-category">{findPortfolioData.category}</div>) : (<div className="art-project-category">Ui Design, Graphic</div>)}

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="art-a art-project-cover">
                            <a
                                data-fancybox="gallery"
                                href={findPortfolioData ? findPortfolioData.image : "#"}

                                className="art-portfolio-item-frame art-horizontal"
                            >
                                {findPortfolioData ? (<img src={findPortfolioData.image} alt="item" />) : (<img src="" alt="item" />)}
                                <span className="art-item-hover">
                                    <i className="fas fa-expand"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="art-section-title">
                            <div className="art-title-frame">
                                <h4>Project details</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="art-a art-card art-fluid-card">
                            <h5 className="mb-15">Description</h5>
                            {/* {findPortfolioData ? (<div className="mb-15">{findPortfolioData.long_description}</div>) : (<div className="mb-15">Description here</div>)} */}
                            {findPortfolioData ? (<div className='portfolio-description' dangerouslySetInnerHTML={{ __html: findPortfolioData.long_description }} />) : (<div className="mb-15">Description here</div>)}

                            <div className="art-buttons-frame">
                                <a
                                    href={findPortfolioData ? findPortfolioData.link : "#"}
                                    className="art-link art-color-link art-w-chevron"
                                    target="_blank"
                                >
                                    Link
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="art-a art-card">
                            <div className="art-table p-15-15">
                                <ul>
                                    <li>
                                        <h6>Start Date:</h6>
                                        {findPortfolioData ? (<span>{findPortfolioData.start_date}</span>) : (<span>DD.MM.YYYY</span>)}

                                    </li>
                                    <li>
                                        <h6>End Date:</h6>
                                        {findPortfolioData ? (<span>{findPortfolioData.end_date}</span>) : (<span>DD.MM.YYYY</span>)}
                                    </li>
                                    <li>
                                        <h6>Status:</h6>
                                        {findPortfolioData ? (<span>{findPortfolioData.status}</span>) : (<span>In progress</span>)}
                                    </li>
                                </ul>
                                <div className='art-portfolio-specific-tech-stack-div'>
                                    <h6>Tech Stack</h6>
                                    {findPortfolioData ? (<div className='art-portfolio-specific-tech-stack-div-sub'>
                                        {findPortfolioData.tech_stack.map((value, index) => (
                                            <SpecificPortfolioTechStack key={index} value={value} />
                                        ))}
                                    </div>) : (<div className='art-portfolio-specific-tech-stack-div-sub'></div>)}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default SpecificProjectDetailComponent;
